import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as routes from 'routes'
import * as pages from '../pages'
import { AuthenticatedRoute } from './AuthenticatedRoute'

const rpt = { subject: 'reporting' as const }

export default function MainRouter() {
  return (
    <Switch>
      {/* <AuthenticatedRoute path={routes.home} exact component={pages.Events} /> */}
      <Redirect from={routes.home} to={routes.locations} exact />
      <Route path={routes.setupMfa} exact component={pages.SetupMFA} />
      <Route path={routes.login} exact component={pages.Login} />
      <Route path={routes.forgotPassword} exact component={pages.ForgotPassword} />
      <Route path={routes.resetPassword} exact component={pages.ResetPassword} />
      <Route path={routes.visitorRequest} exact component={pages.VisitorRequest} />
      <AuthenticatedRoute path={routes.events} exact component={pages.Events} />
      <AuthenticatedRoute path={routes.eventDetail} component={pages.EventDetail} />
      <AuthenticatedRoute path={routes.people} exact component={pages.People} subject="people" />
      <AuthenticatedRoute path={routes.personDetail} component={pages.PersonDetail} />
      <AuthenticatedRoute path={routes.devices} exact component={pages.Devices} subject="things" />
      <AuthenticatedRoute path={routes.deviceLiveFeed} component={pages.DeviceLiveFeed} withoutHeader />
      <AuthenticatedRoute path={routes.deviceDetail} component={pages.DeviceDetail} />
      <AuthenticatedRoute path={routes.settings} component={pages.Settings} />
      <AuthenticatedRoute admin path={routes.admin} exact component={pages.Admin} />
      <Redirect from={routes.notifications} exact to={{ pathname: routes.adminNotifications }} />
      <Route path={routes.teamsNotifications} exact component={pages.TeamsNotifications} />
      <AuthenticatedRoute admin path={routes.adminTab} exact component={pages.Admin} />
      <AuthenticatedRoute path={routes.userDetail} component={pages.UserDetail} />
      <AuthenticatedRoute admin path={routes.accessGroupsDetail} component={pages.AccessGroupDetail} />
      <AuthenticatedRoute path={routes.locationZoneDetail} component={pages.LocationZoneDetail} />
      <Redirect from={routes.locationDetail} to={routes.locationZones} exact />
      <AuthenticatedRoute path={routes.locationZones} component={pages.LocationZones} />
      <AuthenticatedRoute path={routes.locationDeviceDetail} component={pages.LocationDeviceDetail} />
      <AuthenticatedRoute path={routes.locationDevices} component={pages.LocationDevices} />
      <AuthenticatedRoute path={routes.locationEvents} component={pages.LocationEvents} />
      <AuthenticatedRoute path={routes.locationAccessEvents} component={pages.LocationAccessEvents} />
      <AuthenticatedRoute path={routes.locationAccessGroupDetail} component={pages.LocationAccessGroupDetail} />
      <AuthenticatedRoute path={routes.locationAccessGroups} component={pages.LocationAccessGroups} />
      <AuthenticatedRoute path={routes.locationEmployees} component={pages.LocationEmployees} />
      <AuthenticatedRoute path={routes.locationVisitors} component={pages.LocationVisitors} />
      <AuthenticatedRoute path={routes.locations} component={pages.Locations} />
      <AuthenticatedRoute path={routes.reporting} exact component={pages.Reporting} {...rpt} />
      <AuthenticatedRoute path={routes.reportingAccessEvents} exact component={pages.ReportingAccessEvents} {...rpt} />
      <AuthenticatedRoute path={routes.reportingBadges} exact component={pages.ReportingBadges} {...rpt} />
      <AuthenticatedRoute path={routes.reportingFacialRec} exact component={pages.ReportingFacialRec} {...rpt} />
      <AuthenticatedRoute path={routes.reportingInactivity} exact component={pages.ReportingInactivity} {...rpt} />
      <AuthenticatedRoute path={routes.reportingEmployees} exact component={pages.ReportingEmployees} {...rpt} />
      <AuthenticatedRoute path={routes.reportingOccupancy} exact component={pages.ReportingOccupancy} {...rpt} />
      <AuthenticatedRoute path={routes.reportingOverview} exact component={pages.ReportingOverview} {...rpt} />
      <AuthenticatedRoute path={routes.reportingDoors} exact component={pages.ReportingDoors} {...rpt} />
      <AuthenticatedRoute path={routes.reportingDownloads} exact component={pages.ReportingDownloads} {...rpt} />
      <AuthenticatedRoute path={routes.reportingBadgeStatus} exact component={pages.ReportingBadgeStatus} {...rpt} />
      <Route render={() => <h1>Page not found</h1>} />
    </Switch>
  )
}
