import { getAuthorizationEventResolutionsList } from 'libs/api'
import { useQuery } from 'hooks'

export function useAuthorizationEventResolutions() {
  const { data, isLoading, error, refetch } = useQuery(
    getAuthorizationEventResolutionsList,
    { limit: 0 },
    { cacheTime: 5000, staleTime: 5000 }
  )
  const resolutions = data?.items ?? []
  const other = resolutions.find((resolution) => resolution.name.toLowerCase() === 'other')
  const sortedResolutions = other ? [...resolutions.filter((item) => item !== other), other] : resolutions

  return { resolutions: sortedResolutions, other, isLoading, error, refetch }
}
