import React from 'react'
import { Button as ChakraButton, Select, SimpleGrid, Spinner } from '@chakra-ui/react'
import InfiniteScroll from 'react-infinite-scroller'
import { Box, Flex, Skeleton, Text, SkeletonProvider } from 'components'
import { OrderProps } from 'modules/Common/ListPage/components/ListPageHeader'
import { useBgColorFromShade, useTextColorFromShade } from 'components/core/utils/shade'
import { useTranslations } from 'hooks'
import translations from './GridView.i18n.json'
import { Column } from 'modules/Common/ListPage'

const DefaultSkeletonItem = () => <Skeleton pt="100%" />

const LoadingSkeleton = ({ SkeletonItem = DefaultSkeletonItem }: { SkeletonItem?: React.ComponentType }) => (
  <SkeletonProvider isLoaded={false}>
    {Array.from({ length: 15 }).map((_, index) => (
      <SkeletonItem key={index} />
    ))}
  </SkeletonProvider>
)

type Props<T> = {
  items: T[] | undefined
  fetchNextPage: () => any
  hasNextPage: boolean
  refetch: () => any
  orderProps?: OrderProps
  columns: Column<T>[]
  gridItemComponent: React.FC<{ data: T }>
  skeletonItemComponent?: React.ComponentType
  getId: (t: T) => number | string
  onItemClick?: (item: T) => void
}

export function GridView<T>({
  items,
  fetchNextPage,
  hasNextPage,
  orderProps,
  columns,
  getId,
  gridItemComponent: GridItem,
  skeletonItemComponent: SkeletonItem,
  onItemClick,
}: Props<T>) {
  const directionBg = useBgColorFromShade(4)
  const directionColor = useTextColorFromShade(1)
  const t = useTranslations(translations)

  const sortingOptions = columns
    .filter((column) => column.sortKey)
    .map((column) => ({
      label: 'title' in column ? column.title : '',
      value: column.sortKey!,
    }))
  return (
    <InfiniteScroll
      loadMore={() => fetchNextPage()}
      hasMore={hasNextPage}
      loader={
        <Box key={0} p={8}>
          <Spinner />
        </Box>
      }
    >
      {orderProps && sortingOptions.length && (
        <Flex align="center" justify="flex-end" mb={4}>
          <Text mr={2}>{t.sortBy}: </Text>
          <Select width="auto" value={orderProps.orderBy} onChange={(e) => orderProps.setOrderBy(e.target.value)}>
            {sortingOptions.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </Select>
          <ChakraButton
            colorScheme="gray"
            bg={directionBg}
            color={directionColor}
            variant="outline"
            onClick={() => orderProps.setOrderDirection(orderProps.orderDirection === 'ASC' ? 'DESC' : 'ASC')}
          >
            <Box opacity={orderProps.orderDirection === 'DESC' ? 1 : 0.3}>↓</Box>
            <Box opacity={orderProps.orderDirection === 'ASC' ? 1 : 0.3}>↑</Box>
          </ChakraButton>
        </Flex>
      )}
      <SimpleGrid minChildWidth={275} spacing={6}>
        {items ? (
          items.length ? (
            items.map((item) => (
              <Box
                pr={[6, 0]}
                key={getId(item)}
                onClick={onItemClick ? () => onItemClick(item) : undefined}
                cursor={onItemClick ? 'pointer' : 'default'}
              >
                <GridItem data={item} />
              </Box>
            ))
          ) : (
            <div>No data</div>
          )
        ) : (
          <LoadingSkeleton SkeletonItem={SkeletonItem} />
        )}
      </SimpleGrid>
    </InfiniteScroll>
  )
}
