import {
  DeviceGroup,
  EventDetail,
  Person,
  PersonAccessGroup,
  PersonBadge,
  BadgeStatus,
  PersonListItem,
  User,
  InactiveItem,
} from 'libs/api'
import { v4 as uuidv4 } from 'uuid'
import { Transaction } from './billing'
import { DeviceItem } from './devices'
import { Label } from './labels'
import { AuditLog } from './users'
import { NotificationItem } from './notifications'
import { Zone } from './zones'
import { AuthorizationEvent } from './authorizationEvents'
import { AccessGroup } from './accessGroups'
import { DeviceGroupStatus } from './deviceGroups'

export const createDummyBadgeStatus = (data?: Partial<BadgeStatus>): BadgeStatus => ({
  badge_id: uuidv4(),
  name: 'none',
  badge_string: '123',
  role: 'Employee',
  locations: ['none'],
  status: 'Enabled',
  history: [],
})

export const createDummyEvent = (data: Partial<EventDetail> = {}): EventDetail => ({
  event_id: uuidv4(),
  description: '',
  event_type: 'other',
  lat: 0,
  lng: 0,
  name: 'test event',
  source: { type: 'user', id: 'test', name: '', place_name: '' },
  status: 'confirmed',
  subject: {
    type: 'person',
    id: 'test',
    first_name: 'Test First',
    last_name: 'Test Last',
    labels: [
      {
        category_id: '',
        color: '#cc0000',
        name: 'Category Name',
        count: 0,
        time_limit: false,
      },
    ],
    color: '',
    image_url: '',
  },
  timestamp: new Date().toISOString(),
  attributes: {
    attachments: [],
    matches: [
      {
        similarity: 0,
        croppedImageUrl: '',
        timestamp: new Date().toISOString(),
        sourceImageUrl: '',
        boundingBox: { height: 0, width: 0, left: 0, top: 0 },
      },
    ],
    similarity: 0,
  },
  address: {
    place_name: 'test place somewhere in the united states of america',
    place_type: ['place'],
  },
  ...data,
})

export const createDummyUser = (data: Partial<User> = {}): User => ({
  enabled: true,
  userName: 'dummytest',
  status: 'CONFIRMED',
  fullName: 'TestFirst TestLast',
  firstName: 'TestFirst',
  lastName: 'TestLast',
  email: 'test@example.com',
  roles: [uuidv4()],
  locationIds: [],
  sub: '',
  tenantId: '',
  ...data,
})

export const createDummyDevice = (data: Partial<DeviceItem> = {}): DeviceItem => ({
  thing_id: uuidv4(),
  name: 'Test Device',
  description: 'test test test test test',
  color: '',
  image_url: '',
  lat: 0,
  lng: 0,
  place_name: 'Test Placename, St. Louis, Missouri 63105, United States of America',
  thing_type_name: 'Camera',
  thing_type_id: uuidv4(),
  last_event_timestamp: '',
  attributes: {},
  links: [],
  config: {},
  device_group_id: uuidv4(),
  ...data,
})

export const createDummyZone = (): Zone => ({
  zone_id: uuidv4(),
  name: 'Test Zone',
  description: 'test test test',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  latitude: 0,
  longitude: 0,
  parent_zone_id: null,
  device_groups: [1, 2, 3].map(() => createDummyDeviceGroup()),
  similarity_threshold: 50,
})

export const createDummyDeviceGroup = (): DeviceGroup => ({
  device_group_id: uuidv4(),
  name: 'Test DeviceGroup',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  zone_id: null,
  devices: [1, 2, 3].map(() => ({ ...createDummyDevice(), direction: 'both' })),
  device_group_type: 'lane',
  status: DeviceGroupStatus.Secure,
})

export const createDummyAccessGroup = (data: Partial<AccessGroup> = {}): AccessGroup => ({
  access_group_id: uuidv4(),
  name: 'Test Access Group',
  zones: [
    {
      zone_id: uuidv4(),
      name: 'test zone',
    },
  ],
  start_time: new Date().toISOString(),
  end_time: new Date().toISOString(),
  device_groups: [
    {
      device_group_id: uuidv4(),
      name: 'test device group 1',
    },
    {
      device_group_id: uuidv4(),
      name: 'test device group 2',
    },
  ],
  ...data,
})

export const createDummyPersonAccessGroup = (data: Partial<AccessGroup> = {}): PersonAccessGroup => ({
  access_group_id: uuidv4(),
  updated_at: new Date(),
  created_at: new Date(),
  name: '___________________________',
  zones: [
    {
      zone_id: uuidv4(),
      name: '_________________________________________',
    },
  ],
  ...data,
})

export const createDummyPersonBadges = (data: Partial<PersonBadge> = {}): PersonBadge => ({
  badge_id: '',
  barcode_string: '',
  badge_string: '',
  status: 'Enabled',
  ...data,
})

export const createDummyAuthorizationEvent = (): AuthorizationEvent => ({
  authorization_event_id: uuidv4(),
  authorized: true,
  created_at: new Date().toISOString(),
  device_id: uuidv4(),
  device_time: new Date().toISOString(),
  person_id: uuidv4(),
  resolution_id: uuidv4(),
  resolved_by: uuidv4(),
  updated_at: new Date().toISOString(),
  zone: {
    name: 'Zone Name',
    zone_id: uuidv4(),
  },
  device: {
    thing_id: uuidv4(),
    name: 'Device Name',
    thing_type_id: uuidv4(),
  },
  person: {
    first_name: 'First Name',
    last_name: 'Last Name',
    person_id: uuidv4(),
    image_url: '',
  },
  badge_string: uuidv4(),
  note: uuidv4(),
  resolved_by_user: null,
  acknowledged: false,
})

export const createDummyPerson = (data: Partial<Person> = {}): Person => ({
  advanced_info: false,
  health_info: false,
  person_id: uuidv4(),
  first_name: 'Test',
  last_name: 'Person',
  home_location: {
    zone_id: '111',
    name: 'Test',
  },
  labels: [
    { category_id: '1', name: 'Category 1', color: '', time_limit: false, count: 0 },
    { category_id: '2', name: 'Category 2', color: '', time_limit: false, count: 0 },
    { category_id: '3', name: 'Category 3', color: '', time_limit: false, count: 0 },
  ],
  color: '#000',
  image_url: '',
  locations: [],
  summary: '',
  employee_string: '123',
  facial_rec_enabled: false,
  allow_double_entry: false,
  appearance: {
    features: [],
    sex: '',
    height: [],
    eyes: [],
    hair: [],
    race: '',
    weight: [],
  },
  contact: {
    social: {
      facebook: [],
      instagram: [],
      twitter: [],
      youtube: [],
      other: [],
    },
  },
  zones: [],
  ...data,
})

export const createDummyPersonListItem = (data: Partial<Person> = {}): PersonListItem => {
  const dummy: PersonListItem = {
    ...createDummyPerson(data),
    locations: [
      {
        location_id: '1',
        type: 'Location',
        loc: { geometry: { type: 'Point', coordinates: [0, 0] } },
        address: { place_type: 'Place', place_name: 'test place somewhere in the united states of america' },
      },
    ],
    zones: [],
  }
  return dummy
}

export const createDummyLabel = (data: Partial<Label> = {}): Label => ({
  category_id: uuidv4(),
  color: '',
  count: 100,
  name: 'Test Label',
  time_limit: false,
  description: 'test test test test test test',
  ...data,
})

export const createDummyTransaction = (data: Partial<Transaction> = {}): Transaction => ({
  created: 1602871293985,
  currency: 'USD',
  id: 'in_000000000000000000000000',
  invoice_pdf: '',
  total: 4200,
  ...data,
})

export const createDummyNotification = (data: Partial<NotificationItem> = {}): NotificationItem => ({
  activity: [{ channel: 'events', eventName: 'create', event_type_names: 'other', filters: [''] }],
  destination: { emails: ['testfirstname.testlastname@testemail.com'] },
  notification_id: uuidv4(),
  notification_type: 'Email' as any,
  ...data,
})

export const createDummyAuditLog = (): AuditLog => ({
  dml_created_by: uuidv4(),
  dml_timestamp: new Date().toISOString(),
  dml_type: 'UPDATE',
  new_row_data: {},
  old_row_data: null,
  row_id: uuidv4(),
  table_name: 'people',
})
export const createDummyInactivityItem = (): InactiveItem => ({
  person_id: uuidv4(),
  badge_enabled: Math.random() > 0.5 ? 'Enabled' : 'Disabled',
  locations: [
    {
      name: 'Dummy Zone',
      zone_id: uuidv4(),
    },
  ],
  last_name: 'Dummy',
  first_name: 'Dummy',
  employee_string: '123',
  last_event_time: new Date().toISOString(),
  image_url: '',
})
