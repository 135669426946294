import { API } from 'aws-amplify'
import { DeviceGroup } from './deviceGroups'
import { ListResponse, PaginationSearchParams, OrderParams } from './types'
import { refetchQueries } from 'hooks'
import { getDeviceGroups } from 'libs/api'

export type Timezone =
  | 'America/New_York'
  | 'America/Chicago'
  | 'America/Denver'
  | 'America/Phoenix'
  | 'America/Los_Angeles'
  | 'America/Anchorage'
  | 'Pacific/Honolulu'

export const timezoneMap: Record<Timezone, string> = {
  'America/New_York': 'EST',
  'America/Chicago': 'CST',
  'America/Denver': 'MST',
  'America/Phoenix': 'MST',
  'America/Los_Angeles': 'PST',
  'America/Anchorage': 'AKST',
  'Pacific/Honolulu': 'HST',
}

export type Zone = {
  created_at: string
  description: string
  latitude: number
  longitude: number
  name: string
  parent_zone_id?: string | null
  updated_at: string
  zone_id: string
  zones?: Zone[]
  device_groups?: DeviceGroup[]
  abbreviation?: string
  similarity_threshold: number
  show_in_occupancy?: boolean
  timezone?: Timezone
}

export type FetchZoneParams = PaginationSearchParams & Pick<Zone, 'parent_zone_id'> & OrderParams

export type ZoneFormValues = {
  name: string
  description: string
  parent_zone_id?: string | null
  latitude: string
  longitude: string
  abbreviation?: string
  similarity_threshold?: string
  timezone?: Timezone
}

export type CreateZonePayload = Pick<Zone, keyof ZoneFormValues>

export async function getZonesList(params: FetchZoneParams): Promise<ListResponse<Zone>> {
  return API.get('zones', '/zones', {
    queryStringParameters: {
      ...params,
      responseFormat: 'list',
    },
  })
}

export async function getZones(params: any): Promise<Zone[]> {
  return API.get('zones', '/zones', { queryStringParameters: params })
}

export async function fetchZone({ id }: { id: number | string }): Promise<Zone> {
  if (!id) return Promise.reject('Zone ID is not defined')

  return API.get('zones', `/zones/${id}`, {})
}

export const deleteZone = async (zone: Zone): Promise<void> => {
  return API.del('zones', `/zones/${zone.zone_id}`, {})
}

export async function createZone(body: CreateZonePayload): Promise<Zone> {
  return API.post('zones', `/zones`, { body })
}

export async function updateZone(zone: Zone, payload: Partial<Zone>): Promise<Zone> {
  return API.put('zones', `/zones/${zone.zone_id}`, { body: payload })
}

export async function refetchZone(zoneId: string) {
  return Promise.all([
    refetchQueries(fetchZone, { id: zoneId }, { exact: true }),
    refetchQueries(getDeviceGroups, { zone_id: [zoneId] }, { exact: true }),
  ])
}
