import React, { MutableRefObject, useContext, useRef, ReactNode, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { useCurrentDate } from './hooks/useCurrentDate'
import { useCurrentUser } from '../../../hooks'
import { Box, Flex, SubtleLink } from 'components'
import { Heading } from '@chakra-ui/react'
import { IconButton, UserAvatar } from 'components'
import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import { OmniSearch, OmniSearchType } from 'modules/OmniSearch/OmniSearch'
import { useLocation } from 'react-router-dom'
import * as routes from 'routes'
import { can } from 'utils/access'
import { useAuthContext } from 'hooks'
import { useMobileDetect } from 'hooks/useMobileDetect'
import { CurrentRoleDropdown } from './CurrentRoleDropdown'

type ContextProps = { titleRef: MutableRefObject<any>; omniSearchType: MutableRefObject<OmniSearchType> }

const HeaderContext = React.createContext<ContextProps | undefined>(undefined)

const HeaderBoxView = styled(Flex)({
  justifyContent: 'space-between',
  lineHeight: '2rem',
})

export const HeaderBox = ({ children }: { children: ReactNode }) => {
  const titleRef = useRef(null)
  const omniSearchType = useRef('default' as const)
  const currentDate = useCurrentDate()
  const user = useCurrentUser()
  const [isSearchMode, setIsSearchMode] = useState(false)
  const location = useLocation()
  const { currentRole } = useAuthContext()
  const { isMobile } = useMobileDetect()

  useEffect(() => {
    setIsSearchMode(false)
    omniSearchType.current = 'default'
  }, [location])

  const canOmniSearch =
    can(currentRole, 'list', 'people') || can(currentRole, 'list', 'things') || can(currentRole, 'list', 'users')
  return (
    <>
      <HeaderContext.Provider value={{ titleRef, omniSearchType }}>
        <HeaderBoxView pt={8} pb={8}>
          <Heading size="lg" ref={titleRef} shade={1} display="flex" alignItems="center" />
          <Flex shade={2} alignItems="center">
            <Box shade={2} mr={5} display={['none', 'block']}>
              {currentDate}
            </Box>
            {canOmniSearch && !isMobile() && (
              <IconButton
                aria-label="search"
                shade={2}
                isRound
                variant="ghost"
                mx={5}
                onClick={() => setIsSearchMode(!isSearchMode)}
              >
                {isSearchMode ? <CloseIcon boxSize={4} /> : <SearchIcon boxSize={4} />}
              </IconButton>
            )}
            <CurrentRoleDropdown display={['none', 'flex']} />
            <SubtleLink
              to={routes.userDetailWithUsername(user.username)}
              display={['none', 'flex']}
              alignItems="center"
            >
              <UserAvatar name={user.fullName} size="xs" ml={5} />
              <Box shade={3} fontWeight="bold" ml={2}>
                {user.username}
              </Box>
            </SubtleLink>
          </Flex>
        </HeaderBoxView>
        {canOmniSearch && isSearchMode ? <OmniSearch type={omniSearchType.current} /> : children}
      </HeaderContext.Provider>
    </>
  )
}

export const HeaderTitle = ({ children }: { children: ReactNode }) => {
  const context = useContext(HeaderContext)

  if (!context) {
    throw Error('`HeaderTitle` must be inside `HeaderContext`')
  }
  if (!context.titleRef.current) {
    return null
  }

  return ReactDOM.createPortal(children, context.titleRef.current)
}

export function useOmniSearchType(type: OmniSearchType) {
  const context = useContext(HeaderContext)

  if (!context) {
    throw Error('`useOmniSearchType` must be inside `HeaderContext`')
  }

  context.omniSearchType.current = type
}
