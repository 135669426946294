import { Skeleton, Stack, ActionButtonLink } from 'components'
import React, { useState, ComponentType } from 'react'
import { Formik, Form, FormikHelpers, FormikConfig, useFormikContext, FormikProps, FormikValues } from 'formik'
import { SectionPane, SectionPaneProps } from './SectionPane'
import { useNotify, useTranslations } from 'hooks'
import translations from './SectionPaneWithForm.i18n.json'
import { can, Subject } from 'utils/access'
import { useAuthContext } from 'hooks'

export type SectionPaneWithFormProps<FormValues> = Omit<SectionPaneProps, 'actions'> &
  Pick<FormikConfig<FormValues>, 'onSubmit' | 'initialValues' | 'validationSchema'> & {
    fields: ComponentType<FormikProps<FormValues>>
    labels?: Partial<{
      edit: string
      close: string
      save: string
    }>
    subject: Subject
    isEditDisabled?: boolean
    onEditStart?: () => void
    onEditEnd?: () => void
    handleDelete?: () => void
    canDelete?: boolean
  }

type FormActionsProps = {
  labels: { close: string; save: string; delete?: string }
  canDelete?: boolean
  handleDelete?: () => void
}

const FormActions = ({ labels, handleDelete, canDelete = false }: FormActionsProps) => {
  const { isSubmitting } = useFormikContext()

  return (
    <Stack isInline spacing={4}>
      {canDelete && (
        <ActionButtonLink colorScheme="red" isDisabled={isSubmitting} onClick={handleDelete}>
          {labels.delete}
        </ActionButtonLink>
      )}
      <ActionButtonLink type="reset" isDisabled={isSubmitting}>
        {labels.close}
      </ActionButtonLink>
      <ActionButtonLink type="submit" isLoading={isSubmitting}>
        {labels.save}
      </ActionButtonLink>
    </Stack>
  )
}

export function SectionPaneWithForm<FormValues extends FormikValues>({
  icon,
  title,
  children,
  labels: partialLabels,
  fields: FieldsComponent,
  onSubmit,
  initialValues,
  validationSchema,
  subject,
  isEditDisabled,
  onEditStart,
  onEditEnd,
  handleDelete,
  canDelete,
  ...systemProps
}: SectionPaneWithFormProps<FormValues>) {
  const t = useTranslations(translations)
  const notify = useNotify()
  const [isEditing, setIsEditing] = useState(false)
  const show = () => {
    setIsEditing(true)
    onEditStart?.()
  }
  const hide = () => {
    setIsEditing(false)
    onEditEnd?.()
  }
  const { currentRole } = useAuthContext()

  const labels = { ...t, ...partialLabels }

  if (!isEditing) {
    return (
      <SectionPane
        icon={icon}
        title={title}
        actions={
          can(currentRole, 'update', subject) && (
            <Skeleton>
              <ActionButtonLink onClick={show} isDisabled={isEditDisabled}>
                {labels.edit}
              </ActionButtonLink>
            </Skeleton>
          )
        }
        {...systemProps}
      >
        {children}
      </SectionPane>
    )
  }

  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      await onSubmit(values, helpers)
      hide()
    } catch (error) {
      notify.error(error as Error)
    }
  }

  const onDelete = async () => {
    try {
      await handleDelete?.()
      hide()
    } catch (error) {
      notify.error(error as Error)
    }
  }

  return (
    <Formik onSubmit={handleSubmit} onReset={hide} initialValues={initialValues} validationSchema={validationSchema}>
      {(formikProps) => (
        <Form>
          <SectionPane
            icon={icon}
            title={title}
            actions={<FormActions labels={labels} canDelete={canDelete} handleDelete={onDelete} />}
            {...systemProps}
          >
            <FieldsComponent {...formikProps} />
          </SectionPane>
        </Form>
      )}
    </Formik>
  )
}
