import { Box } from 'components'
import { BoxProps } from '@chakra-ui/react'
// import { useViewMode } from 'hooks/useViewMode'
import { DebouncedSearchInput } from './DebouncedSearchInput'
// import { MapSearch } from 'modules/Common/MapView'
import React from 'react'

interface Props extends BoxProps {
  placeholder: string
  initialValue: string
  setValue: (search: string) => void
}

export function ViewModeSearch({ placeholder, initialValue, setValue, ...props }: Props) {
  // const { activeMode } = useViewMode()
  return (
    <Box mb={4} {...props}>
      {/* {activeMode === ViewMode.MAP ? (
        <MapSearch placeholder={placeholder} initialValue={initialValue} />
      ) : ( */}
      <DebouncedSearchInput placeholder={placeholder} initialValue={initialValue} onDebounce={setValue} />
      {/* )} */}
    </Box>
  )
}
