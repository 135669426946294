import { Heading } from '@chakra-ui/react'
import { Skeleton } from 'components'
import { ListResponse } from 'libs/api'
import { last } from 'lodash-es'
import React from 'react'
import { useIntl } from 'react-intl'
import { InfiniteData } from 'react-query'

export type ItemCountProps = {
  data: InfiniteData<ListResponse<unknown, unknown>> | undefined
  countLabel: string
  size?: string
}

export function ItemCount({ data, countLabel, size = 'md' }: ItemCountProps) {
  const intl = useIntl()
  if (data?.pages && (last(data?.pages)?.totalCount ?? 0) < 0) {
    return <></>
  }

  return (
    <Skeleton isLoaded={Boolean(data?.pages.length)} display="inline-block">
      <Heading size={size} shade={1}>
        {intl.formatMessage(countLabel, { count: last(data?.pages)?.totalCount ?? 0 })}
      </Heading>
    </Skeleton>
  )
}
