import { Column } from 'modules/Common/ListPage/types'
import { ReactNode } from 'react'

export type ParsedColumn<T> = {
  key: string
  title?: string
  flex?: number
  render: (item: T, options: { refetch: () => Promise<any> }) => ReactNode
  width?: number | string
  textAlign: 'left' | 'center'
  sortKey?: string
  isInline?: boolean
}

export type ParsedColumns<T> = ParsedColumn<T>[]

export function parseColumns<T>(columns: Column<T>[]): ParsedColumns<T> {
  return columns.map((column) => ({
    ...column,
    key: 'key' in column ? column.key : column.title,
    title: 'title' in column ? column.title : undefined,
    flex: column.width ? undefined : 1,
    render: 'field' in column ? (item: T) => (item[column.field] as any) as string : column.render,
    textAlign: column.textAlign ?? 'left',
    isInline: column.isInline ?? true,
  }))
}
