import { BuiltinUserRoles, Role } from 'libs/api'

// Everything an Admin can do + edit device types
export const hasKeeeAdminAccess = (role?: Role) => {
  return BuiltinUserRoles.KeeeAdmin === role?.role_id
}

type Grant = string & { readonly _: Grant }

export type Action = 'create' | 'read' | 'update' | 'delete' | 'list'

export const subjects = [
  'access_groups',
  'authorization_events',
  'authorization_event_resolutions',
  'badges',
  'device_groups',
  'device_links',
  'people',
  'person_notes',
  'person_notes.sensitive',
  'reporting',
  'roles',
  'things',
  'thing_types',
  'users',
  'zones',
  'zones.root',
  'visitor_requests',
] as const

export type Subject<K extends number = number> = typeof subjects[K]

export function grant(action: Action, subject: Subject) {
  return `${subject}.${action}` as Grant
}

// Very basic RBAC, if a more complex need arrises we can replace it with a lib
export function can(currentRole: Role | undefined, action: Action, subject: Subject): boolean {
  if (!currentRole) return false
  return currentRole.permissions.includes(grant(action, subject))
}
