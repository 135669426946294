import { API } from 'aws-amplify'
import { ListResponse, OrderParams, PaginationSearchMapParams } from './types'
import { Attachment, GeocodingFeature } from 'types'
import { isUndefined, omitBy } from 'lodash-es'
import { ScreeningStatus } from './events'
import { getMemoizedLabels, Label } from './labels'
import { getNextCursor, multiValueParam } from './utils'
import { Zone } from './zones'

export type PersonAppearance = {
  eyes: Array<{ color: string }>
  features: Array<{ area: string; description: string; image_url: string; type: string; weight: number }>
  hair: Array<{ color: string; style: string }>
  height: Array<{ height: number }>
  race: string
  sex: string
  weight: Array<{ weight: number }>
}

export type PersonContact = {
  social?: {
    twitter: string[]
    facebook: string[]
    instagram: string[]
    youtube: string[]
    other: string[]
  }
  phone?: PersonPhone[]
  email?: PersonEmail[]
}

export type PersonPhone = {
  number: string
  type: 'Home' | 'Work' | 'Cellphone' | 'Other'
}

export type PersonEmail = {
  account: string
  type: string
}

export type PersonVisitorRequest = {
  access_groups_ids: string[]
  zone_ids: string[]
  id: string
  person_id: string
  reason: string
  email: string
  state: VisitorRequestState
  requester_email: string
  requester_name: string
  visit_date_time: string
  company: string
  person_visiting: string
  zones: Pick<Zone, 'zone_id' | 'name'>[]
  home_location: Pick<Zone, 'zone_id' | 'name'>
  badge?: PersonBadge
  first_name?: string
  last_name?: string
  employee_string?: string
  image_url?: string
  barcode_string?: string
}

export const badgeRoles = [
  { name: 'Employee', color: '#D3D3D3', textColor: '#000' },
  { name: 'Contractor', color: '#D3D3D3', textColor: '#9400D3' },
  { name: 'Temporary', color: '#D3D3D3', textColor: '#2760F8' },
  { name: 'Customer', color: '#FF3A00', textColor: '#FFF' },
  { name: 'Security', color: '#D3D3D3', textColor: '#03A678' },
  { name: 'HRI', color: '#FF0000', textColor: '#FFFFFF', roleText: 'Employee', highVisibiltyText: true },
  { name: 'HRI Temporary', color: '#FF0000', textColor: '#FFFFFF', roleText: 'Temporary', highVisibiltyText: true },
  { name: 'HRI Security', roleText: 'Security', color: '#FF0000', textColor: '#FFFFFF', highVisibiltyText: true },
  { name: 'HRI Contractor', roleText: 'Contractor', color: '#FF0000', textColor: '#FFFFFF', highVisibiltyText: true },
]

export const visitorBadgeRoles = badgeRoles.filter((role) => role.name !== 'Employee' && role.name !== 'Security')

type BadgeCustomFields = Record<string, string | number | boolean | undefined>

export type PersonBadge = {
  badge_id: string
  badge_string: string
  facility_string?: string
  status: 'Enabled' | 'Disabled'
  created_at?: string
  expires_at?: string
  custom_fields?: BadgeCustomFields
  updated_at?: string
  created_by?: string
  created_by_user?: { family_name: string; given_name: string; user_id: string } | null
  valid_from?: string
  valid_until?: string | null
  barcode_string?: string
  pin_string?: string
  status_changes?: { status: string; timestamp: Date }[]
}

export type BadgeStatus = {
  badge_id: string
  name: string
  badge_string: string
  role: string
  locations: string[]
  status: string
  history: { status: string; timestamp: Date }[]
}

export type PersonNote = {
  person_note_id: string
  person_id: string
  note: string
  sensitive: boolean
}

export type BadgeInformation = {
  name: string
  address: string
  employeeString: string
  badgeString: string
  barcodeString?: string
}

export type PersonAccessGroup = {
  access_group_id: string
  created_at: Date
  updated_at: Date
  name: string
  zones: Pick<Zone, 'name' | 'zone_id'>[]
}

export type Person = {
  advanced_info: boolean
  health_info: boolean
  person_id: string
  first_name: string
  last_name: string
  image_url: string
  labels: Label[]
  color: string // | null
  locations: PersonLocation[]
  home_location: Pick<Zone, 'zone_id' | 'name'>
  summary: string
  attachments?: Attachment[]
  photos?: null | string[]
  appearance?: PersonAppearance
  contact?: PersonContact
  date_of_birth?: Array<{ day: number; month: number; year: number; type: 'verified' | string }>
  personal_ties?: string[]
  badge?: PersonBadge
  facial_rec_enabled: boolean
  allow_double_entry: boolean
  employee_string: string | null
  access_groups?: PersonAccessGroup[]
  zones: Pick<Zone, 'zone_id' | 'name'>[]
  company_name?: string
  visitor_request?: PersonVisitorRequest
}

export type PersonListItem = Omit<Person, 'locations'> & {
  locations: PersonListLocation[]
  health_screening?: ScreeningStatus
  total_count?: number
}

// this is in people list, but it is not when fetching individual person
export type PersonListLocation = {
  location_id: string
  type: string
  address: {
    place_name: string
    place_type: string
    accuracy?: string
  }
  loc: Pick<GeocodingFeature, 'geometry'>
  cluster?: {
    id: number
    count: number
    center: [number, number]
  }
}

export type PersonLocationAddress = {
  place_name: string
  place_type: string[]
  accuracy?: string
}

export type PersonLocation = {
  location_id: string
  type: string
  address?: PersonLocationAddress // TODO can it be undefined?
  lat: number
  lng: number
  date?: string | null
}

export enum ScreeningSubmisionStatus {
  SUBMITTED = 'submitted',
  PENDING = 'pending',
}

export type PeopleFilters = {
  labels?: string[]
  radius?: string
  health_screening?: ScreeningStatus | ScreeningSubmisionStatus
  health_info?: boolean
  zone_id?: string[]
  is_visitor?: boolean
  created_after?: string
}

export type VisitorBadge = {
  visitor_badge_id: string
  badge_string: string
}

type APIPerson = Omit<Person, 'labels'> & {
  labels: string[]
}

// TODO Update API and remove this transformation
export const transformApiPerson = (labelsMap: Record<string, Label>) => (person: APIPerson): Person => ({
  ...person,
  labels: person.labels?.map((category_id) => labelsMap[category_id]).filter(Boolean) ?? [],
})

type FetchParams = PeopleFilters & PaginationSearchMapParams & OrderParams

export async function exportBadgeStatus(filters: {
  zone_id?: string[]
  badge_status?: string
  start_time?: string
  end_time?: string
  access_group_id?: string | string[]
}): Promise<{ Key: string }> {
  return API.get('people', '/people/export/badge-audit', {
    queryStringParameters: filters,
  })
}

export async function fetchBadgeStatusList({
  zone_id,
  start_time,
  badge_status,
  end_time,
  access_group_id,
  offset = 0,
}: {
  badge_status?: string
  offset?: number
  start_time?: string
  end_time?: string
  zone_id?: string[]
  access_group_id?: string | string[]
}): Promise<ListResponse<BadgeStatus>> {
  const queryStringParameters = omitBy(
    {
      responseFormat: 'list',
      offset,
      badge_status,
      start_time,
      end_time,
      zone_id,
      access_group_id,
    },
    isUndefined
  )

  const response = await API.get('people', '/people/badge-audit', { queryStringParameters })

  return {
    ...response,
    nextCursor: getNextCursor(response, { offset }),
  }
}

export async function fetchPeopleList({
  offset = 0,
  limit = 10,
  is_visitor = false,
  zone_id,
  ...params
}: FetchParams): Promise<ListResponse<PersonListItem, FetchParams>> {
  const queryStringParameters = omitBy(
    {
      responseFormat: 'list',
      offset,
      limit,
      is_visitor,
      zone_id: multiValueParam(zone_id),
      ...params,
    },
    isUndefined
  )
  const [labelsMap, peopleResponse] = await Promise.all([
    getMemoizedLabels(),
    API.get('people', '/people', { queryStringParameters }),
  ])

  const { items } = peopleResponse

  return {
    ...peopleResponse,
    items: items.map(transformApiPerson(labelsMap)),
    nextCursor: getNextCursor(peopleResponse, { offset }),
  }
}

export async function fetchPerson({ id }: { id: string }): Promise<Person> {
  const [labelsMap, person] = await Promise.all([getMemoizedLabels(), API.get('people', `/people/${id}`, {})])

  return transformApiPerson(labelsMap)(person)
}

export async function deletePerson(person: Person | PersonListItem): Promise<any> {
  return API.del('people', `/people/${person.person_id}`, {})
}

export type UpdatePersonValues = Partial<Omit<Person, 'labels'>> & {
  labels?: string[]
  zone_ids?: string[]
}

export function updatePerson(id: string, body: UpdatePersonValues) {
  return API.put('people', `/people/${id}`, {
    body,
  })
}

export interface ImportPersonPayload {
  first_name: string
  last_name: string
  summary?: string
  image_url?: string
  facial_rec_enabled: boolean
  employee_string?: string
  badge_string?: string
  zone_ids: string[]
  access_group_ids: string[]
}

export function bulkAddPeople(data: ImportPersonPayload[], email: String) {
  return API.post('people', `/people/bulk`, {
    body: {
      email,
      data,
    },
  })
}

export type PersonFormValues = {
  first_name: string
  last_name: string
  labels?: string[]
  summary?: string
  advanced_info?: boolean
  health_info?: boolean
  image_url?: string
  facial_rec_enabled?: boolean
  employee_string?: string
  barcode_string?: string
  zone_ids?: string[]
  access_group_ids?: string[]
  is_visitor?: boolean
}

export function createPerson(body: PersonFormValues): Promise<Person> {
  return API.post('people', `/people`, { body })
}

export function fetchPersonLocations({ id }: { id: string }): Promise<PersonLocation[]> {
  return API.get('people', `/people/${id}/locations`, {})
}

export type LocationsAddress = {
  location_id?: string
  date?: string
  type: string
  loc: { place_name: string[]; geometry?: never } | GeocodingFeature
}

type AddPersonLocationsParams = {
  id: string
  locations: LocationsAddress[]
}

export async function addPersonLocations({ id, locations }: AddPersonLocationsParams) {
  return API.post('people', `/people/${id}/locations`, {
    body: { locations },
  })
}

type DeletePersonLocationsParans = {
  id: string
  locationIds: string[]
}

export async function deletePersonLocations({ id, locationIds }: DeletePersonLocationsParans) {
  return API.del('people', `/people/${id}/locations`, {
    body: { locations: locationIds },
  })
}

type AddPersonAccessGroupsParams = {
  id: string
  accessGroupIds: string[]
}

export async function addPersonAccessGroups({ id, accessGroupIds }: AddPersonAccessGroupsParams) {
  return API.post('people', `/people/${id}/access-groups`, {
    body: { access_groups: accessGroupIds },
  })
}

type DeletePersonAccessGroupsParams = {
  id: string
  accessGroupIds: string[]
}

export async function deletePersonAccessGroups({ id, accessGroupIds }: DeletePersonAccessGroupsParams) {
  return API.del('people', `/people/${id}/access-groups`, {
    body: { access_groups: accessGroupIds },
  })
}

// 2023-07-14: dropped use of it in createBadge to allow for visitor badges to be created
// in one request
/*type CreateBadgePayload = Pick<PersonBadge, 'badge_string' | 'custom_fields'> & {
  status?: PersonBadge['status']
}*/

export async function createBadge(personId: string, body: Partial<PersonBadge>) {
  const { status } = body

  return API.post('people', `/people/${personId}/badges`, {
    body: { status: status ?? 'Enabled', ...body },
  })
}

export async function updateBadge(personId: string, body: Partial<PersonBadge>) {
  return API.put('people', `/people/${personId}/badges`, { body })
}

export async function fetchPeopleAccessGroups({ person_id }: { person_id: string }): Promise<PersonAccessGroup[]> {
  return API.get('people', `/people/${person_id}/access-groups`, {})
}

export function fetchPersonNotes({ id }: { id: string }): Promise<PersonNote[]> {
  return API.get('people', `/people/${id}/notes`, {})
}

export function fetchPersonBadges({ id }: { id: string }): Promise<PersonBadge[]> {
  return API.get('people', `/people/${id}/badges`, {})
}

type AddPersonNotesParams = {
  id: string
  notes: Pick<PersonNote, 'note' | 'sensitive'>[]
}

export async function addPersonNotes({ id, notes }: AddPersonNotesParams) {
  return API.post('people', `/people/${id}/notes`, {
    body: { notes },
  })
}

type DeletePersonNotesParams = {
  id: string
  personNoteIds: string[]
}

export async function deletePersonNotes({ id, personNoteIds }: DeletePersonNotesParams) {
  return API.del('people', `/people/${id}/notes`, {
    body: { notes: personNoteIds },
  })
}

type FetchVisitorBadgesParams = { assigned?: boolean }

export async function fetchVisitorBadges({ assigned }: FetchVisitorBadgesParams): Promise<VisitorBadge[]> {
  return API.get('people', `/people/visitor-badges`, {
    queryStringParameters: omitBy(
      {
        assigned,
      },
      isUndefined
    ),
  })
}

export async function exportEmployeesCSV({ zoneId }: { zoneId?: string | string[] }) {
  return API.get('people', '/people/export', {
    queryStringParameters: {
      zone_id: zoneId,
    },
  })
}

export enum VisitorRequestState {
  PENDING = 'PENDING',
  ANSWERED = 'ANSWERED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

export interface VisitorRequest {
  id: string
  email: string
  requester_email: string
  state?: VisitorRequestState
  badge_id?: string
  badge_string?: string
  created_at?: string
  visitor_id?: string
  visit_date_time?: string
  // TODO type
  person?: any
}

interface CreateVisitorRequestPayload {
  email: string
  requester_email: string
  requester_name: string
  visit_date_time: string
  zone_ids: string[]
  access_group_ids: string[]
}

export const createVisitorRequest = async (payload: CreateVisitorRequestPayload) =>
  API.post('people', '/people/visitor-requests/', {
    body: payload,
  })

export interface AnswerVisitorRequestPayload {
  id: string
  first_name: string
  last_name: string
  photo: string
  reason: string
  company: string
  person_visiting: string
}

export interface InternalUpdateVisitorRequestPayload {
  id: string
  approved: boolean
}

export const answerVisitorRequest = async (payload: AnswerVisitorRequestPayload, token: string) =>
  API.put('people', `/people/visitor-requests/${payload.id}/visitor-answer`, {
    body: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
export const internalUpdateVisitorRequest = async (payload: InternalUpdateVisitorRequestPayload) => {
  return API.put('people', `/people/visitor-requests/${payload.id}/internal-answer`, {
    body: payload,
  })
}

interface FetchVisitorRequestParams {
  id: string
  token: string
}

export async function getVisitorRequest({ id, token }: FetchVisitorRequestParams): Promise<PersonVisitorRequest> {
  return API.get('people', `/people/visitor-requests/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
