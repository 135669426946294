import { AuthorizationEventMessage, MessageType } from 'components/AuthorizationEventsProvider'
import { parseISO } from 'date-fns'
import { AuthorizationEvent, DeviceGroup } from 'libs/api'
import { transformHistoricEvent } from 'utils/authorizationEvents/transformHistoricEvent'

export function getInitialRemoteHistory(
  historicEvents: AuthorizationEvent[],
  deviceGroup: DeviceGroup
): AuthorizationEventMessage[] {
  const lte = deviceGroup.last_transit_event
  // 3 situations can clear the lane: passed transit event, resolved auth. event, and acknowledged auth. event
  const clearedTransitDate = lte?.status === 'passed' ? parseISO(lte.device_time) : new Date(0)
  const groupEvents = historicEvents.filter(
    (event) =>
      event.device_group?.device_group_id === deviceGroup.device_group_id &&
      parseISO(event.device_time) > clearedTransitDate
  )
  const lastClearedEventIndex = groupEvents.findIndex((event) => event.resolution_id || event.acknowledged)
  return groupEvents
    .slice(0, lastClearedEventIndex > -1 ? lastClearedEventIndex : undefined)
    .reverse()
    .map((event) => ({ type: MessageType.AUTHORIZATION_EVENT, data: transformHistoricEvent(event) }))
}
