import styled from '@emotion/styled'

export const Container = styled.div``

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const BadgeFaceControls = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
`

export const BadgeFaceContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0.4px 1.3px rgba(0, 0, 0, 0.021), 0 1px 3.2px rgba(0, 0, 0, 0.03), 0 1.9px 6px rgba(0, 0, 0, 0.036),
    0 3.4px 10.7px rgba(0, 0, 0, 0.043), 0 6.3px 20.1px rgba(0, 0, 0, 0.051), 0 15px 48px rgba(0, 0, 0, 0.07);

  ${BadgeFaceControls} {
    transition: opacity 0.3s;
    opacity: 0;
  }

  &:hover {
    ${BadgeFaceControls} {
      opacity: 1;
    }
  }
`
