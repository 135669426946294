import { API } from 'aws-amplify'
import { DeviceGroup } from './deviceGroups'
import { LimitOffsetCursor, ListResponse, PaginationSearchParams } from './types'
import { getNextCursor } from './utils'
import { Zone } from './zones'
import { Person } from 'libs/api'

export type AccessGroup = {
  access_group_id: string
  name: string
  count?: number
  device_groups?: Pick<DeviceGroup, 'device_group_id' | 'name'>[]
  zones: Pick<Zone, 'zone_id' | 'name'>[]
  start_time: string
  end_time: string
}

export type AccessGroupPerson = Person & {
  status: string
}

export type CreateAccessGroupPayload = { name: string; zone_ids: string[]; start_time: string; end_time: string }

export type UpdateAccessGroupPayload = Partial<CreateAccessGroupPayload>

type FetchAccessGroupsParams = PaginationSearchParams & {
  zone_id?: string[]
}

export const getAccessGroupsList = async (cursor: FetchAccessGroupsParams = {}): Promise<ListResponse<AccessGroup>> => {
  const response = await API.get('access-groups', '/access-groups', {
    queryStringParameters: {
      responseFormat: 'list',
      ...cursor,
    },
  })

  return {
    ...response,
    nextCursor: getNextCursor(response, cursor),
  }
}

export const getAllAccessGroups = async ({
  rootZoneId,
}: {
  rootZoneId?: string
  responseFormat?: string
  limit?: number
}): Promise<AccessGroup[]> => {
  return API.get('access-groups', '/access-groups', {
    queryStringParameters: {
      zone_id: rootZoneId,
    },
  })
}

export const deleteAccessGroup = async (accessGroup: AccessGroup) => {
  return API.del('access-groups', `/access-groups/${accessGroup.access_group_id}`, {})
}

export const createAccessGroup = async (payload: CreateAccessGroupPayload) => {
  return API.post('access-groups', `/access-groups`, {
    body: payload,
  })
}

export const updateAccessGroup = async (accessGroup: AccessGroup, payload: UpdateAccessGroupPayload) => {
  return API.put('access-groups', `/access-groups/${accessGroup.access_group_id}`, {
    body: payload,
  })
}

export async function fetchAccessGroup({ id }: { id: string }): Promise<AccessGroup> {
  return API.get('access-groups', `/access-groups/${id}`, {})
}

type FetchAccessGroupPeopleParams = { access_group_id: string } & LimitOffsetCursor
export async function fetchAccessGroupPeople({
  access_group_id,
  limit,
  offset,
}: FetchAccessGroupPeopleParams): Promise<AccessGroupPerson[]> {
  return API.get('access-groups', `/access-groups/${access_group_id}/people`, {
    queryStringParameters: {
      limit,
      offset,
    },
  })
}

export type AddAccessGroupPeoplePayload = {
  people: string[]
}

export async function addAccessGroupPeople(
  accessGroup: Pick<AccessGroup, 'access_group_id'>,
  payload: AddAccessGroupPeoplePayload
) {
  return API.put('access-groups', `/access-groups/${accessGroup.access_group_id}/people`, {
    body: payload,
  })
}

export type AddAccessGroupDeviceGroupsPayload = {
  device_groups: string[]
}

export async function addAccessGroupDeviceGroups(accessGroup: AccessGroup, payload: AddAccessGroupDeviceGroupsPayload) {
  return API.post('access-groups', `/access-groups/${accessGroup.access_group_id}/device-groups`, {
    body: payload,
  })
}

type DeleteAccessGroupDeviceGroupsParans = AddAccessGroupDeviceGroupsPayload

export async function deleteAccessGroupDeviceGroups(
  accessGroup: AccessGroup,
  payload: DeleteAccessGroupDeviceGroupsParans
) {
  return API.del('access-groups', `/access-groups/${accessGroup.access_group_id}/device-groups`, {
    body: payload,
  })
}
