import React, { forwardRef, useLayoutEffect } from 'react'
import Konva from 'konva'
import { Image } from 'react-konva'
import { useBarcodeImage } from '../../hooks'

type Props = { code: string; useShortBarcode: boolean; onLoaded?: (image: HTMLImageElement) => void } & Omit<
  Konva.ImageConfig,
  'image'
>

export const Barcode = forwardRef(({ code, useShortBarcode = false, onLoaded, ...imageConf }: Props, ref) => {
  const image = useBarcodeImage(code, useShortBarcode)

  useLayoutEffect(() => {
    onLoaded?.(image)
  }, [image, onLoaded])

  return <Image {...imageConf} image={image} ref={ref as any} />
})
