import React, { useCallback, useRef, useState } from 'react'
import { Layer, Stage, Rect, Text } from 'react-konva'
import { Button, ImageURL, Barcode, EditableText } from 'components'
import { BadgeFaceContainer, Container } from './Badge.styed'
import Konva from 'konva'
import { BadgeControls } from './BadgeControls'
import { BadgeInformation, badgeRoles, Person, PersonVisitorRequest } from 'libs/api'
import { jsPDF } from 'jspdf'
import { useTranslations } from 'hooks'
import translations from './Badge.i18n.json'

type BadgeProps = {
  employee: Person | PersonVisitorRequest
  templateFront: string
  templateBack: string
  width: number
  height: number
  onUpdate?: (changes: Partial<BadgeInformation>) => void
  isLoading?: boolean
}

export const Badge: React.FC<BadgeProps> = ({
  employee,
  templateFront,
  templateBack,
  width,
  height,
  onUpdate,
  isLoading,
}) => {
  const barcodeRef = useRef<Konva.Image>()
  const frontStageRef = useRef<Konva.Stage | null>(null)
  const backStageRef = useRef<Konva.Stage | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageDimension, setImageDimension] = useState<{ width: number; height: number }>({ width: 1, height: 1 })
  const [barcodeDimensions, setBarcodeDimensions] = useState<{ width: number; height: number }>({ width: 1, height: 1 })
  const address = employee.badge?.custom_fields?.address
    ? `${employee.badge?.custom_fields?.address}`
    : employee.zones.map((zone) => zone.name).join(', ')

  // TODO remove this ASAP
  const manassasBadge = address.includes('Manassas-VA')

  const [localBadgeState, setLocalBadgeState] = useState<BadgeInformation>({
    address,
    name:
      (employee.badge?.custom_fields?.name as string) || `${employee.first_name}\n${employee.last_name}`.toUpperCase(),
    badgeString: employee.badge?.badge_string || '',
    employeeString: employee.employee_string || '',
    barcodeString: employee.badge?.barcode_string || '',
  })

  const [enableSaveButton, setEnableSaveButton] = useState(false)
  const t = useTranslations(translations)

  const onImageLoaded = useCallback(
    (img: HTMLImageElement, ref: Konva.Image | null) => {
      if (!ref) {
        return
      }
      setImageDimension({ width: ref.attrs.width, height: ref.attrs.height })
    },
    [setImageDimension]
  )

  const imagePosition = {
    x: 50,
    y: 130,
  }

  const onBarcodeLoaded = useCallback(() => {
    if (barcodeRef.current) {
      // Delay the use of the ref since the barcode library is not resolving the image element right away
      setTimeout(() => {
        const barcodeImage = barcodeRef.current?.attrs.image
        setBarcodeDimensions({ width: barcodeImage.width, height: barcodeImage.height })
      }, 100)
    }
  }, [])

  async function onPrint() {
    if (frontStageRef.current && backStageRef.current) {
      // Standard DPI assumed to be 96. Increase to 600 DPI.
      const scaleFactor = 1.5

      // Default export is a4 paper, portrait, using millimeters for units
      // Adjust the document size according to the scaleFactor
      const doc = new jsPDF({
        format: [244.8 * scaleFactor, 158.4 * scaleFactor],
        orientation: 'landscape',
      })

      const frontCanvas = frontStageRef.current!.toCanvas({
        pixelRatio: scaleFactor,
      })
      doc.addImage(frontCanvas, 'JPEG', 0, 0, 244.8 * scaleFactor, 158.4 * scaleFactor)

      doc.addPage()

      // Fix printer upside down for the back stage
      const backStage = backStageRef.current!
      backStage.rotation(180)
      backStage.x = backStage.width
      backStage.y = backStage.height

      // Scale and draw the back stage to the canvas
      const backCanvas = backStage.toCanvas({
        pixelRatio: scaleFactor,
      })

      doc.addImage(backCanvas, 'JPEG', 0, 0, 244.8 * scaleFactor, 158.4 * scaleFactor)

      doc.autoPrint()
      doc.output('dataurlnewwindow')
    }
  }

  let barcodePosition = {
    x: width / 2 - barcodeDimensions.width / 2,
    y: height - barcodeDimensions.height - 20,
  }

  if (manassasBadge) {
    barcodePosition.y -= 25
    barcodePosition.x = width / 2 - barcodeDimensions.width / 2 - 70
  }
  function onUpdateBadge(attributeName: keyof BadgeInformation, attributeValue: string) {
    setLocalBadgeState((current) => ({ ...current, [attributeName]: attributeValue }))
    setEnableSaveButton(true)
  }

  const badgeRole = badgeRoles.find((role) => role.name === employee.badge?.custom_fields?.badge_role) ?? badgeRoles[0]

  const approvedMetalException = employee.badge?.custom_fields?.approved_metal_exception

  const isVisitorRequest = employee.hasOwnProperty('requester_email')

  // This was done to allow for really long names
  let nameSize = 44
  const longestNameComponent = localBadgeState.name.split('\n').sort((a, b) => b.length - a.length)[0]
  if (longestNameComponent.length >= 10) {
    // Starting at 10 characters, for every 5
    // characters, shrink the font size by 1
    const totalOffset = (longestNameComponent.length - 15) / 5 + 1
    nameSize = 30 - totalOffset
  }
  return (
    <Container>
      <BadgeFaceContainer>
        <Stage width={width} height={height} ref={frontStageRef}>
          <Layer>
            <Rect width={width} height={height} fill={badgeRole.color} />
            <Rect width={58} height={height - 50} y={21} x={width - 78} fill={badgeRole.color} />
            <ImageURL x={0} y={0} src={templateFront} width={width} />
            <ImageURL
              src={employee.image_url!}
              x={imagePosition.x}
              y={imagePosition.y}
              strokeWidth={3}
              stroke="black"
              width={250}
              maxHeight={280}
              onLoaded={onImageLoaded}
            />
            <Rect width={300} height={80} y={430} x={30} fill={badgeRole.color} />

            <Text
              text={badgeRole.roleText?.toUpperCase() || badgeRole.name.toUpperCase()}
              fontSize={34}
              align="center"
              fill={badgeRole.textColor}
              fontStyle="bold"
              x={50}
              y={height - 55}
              width={250}
              fontFamily="'Avenir Bold', sans-serif"
            />
            {isVisitorRequest ? (
              <Text
                text={localBadgeState.name}
                fontSize={48}
                x={330}
                y={130}
                fontStyle="bold"
                fontFamily="'Avenir Bold', sans-serif"
                align="left"
                width={300}
              />
            ) : (
              <EditableText
                value={localBadgeState.name}
                onChange={(newValue) => onUpdateBadge('name', newValue)}
                fontSize={nameSize}
                x={330}
                y={130}
                fill={badgeRole.highVisibiltyText ? badgeRole.textColor : undefined}
                fontStyle="bold"
                fontFamily="'Avenir Bold', sans-serif"
                align="left"
                width={300}
              />
            )}
            {isVisitorRequest ? (
              <Text
                text={`Visiting From: ${(employee as PersonVisitorRequest).company}`}
                fontSize={28}
                fontStyle="bold"
                fontFamily="'Avenir Bold', sans-serif"
                x={330}
                y={230}
              />
            ) : (
              <EditableText
                value={`Company ID: ${localBadgeState.employeeString}`}
                onChange={(newValue) => onUpdateBadge('employeeString', newValue)}
                fontSize={28}
                fill={badgeRole.highVisibiltyText ? badgeRole.textColor : undefined}
                fontStyle="bold"
                fontFamily="'Avenir Bold', sans-serif"
                x={330}
                y={230}
                placeholder={t.employeeString}
              />
            )}
            {localBadgeState?.barcodeString ? (
              isVisitorRequest ? (
                <Text
                  value={`Badge ID: ${localBadgeState.barcodeString}`}
                  fontSize={28}
                  fontStyle="bold"
                  fontFamily="'Avenir Bold', sans-serif"
                  x={330}
                  y={270}
                />
              ) : (
                <EditableText
                  value={`Badge ID: ${localBadgeState.barcodeString}`}
                  fontSize={28}
                  fill={badgeRole.highVisibiltyText ? badgeRole.textColor : undefined}
                  fontStyle="bold"
                  fontFamily="'Avenir Bold', sans-serif"
                  x={330}
                  y={270}
                />
              )
            ) : (
              <></>
            )}
            <EditableText
              value={localBadgeState.address}
              onChange={(newValue) => onUpdateBadge('address', newValue)}
              fontSize={34}
              fontStyle="bold"
              fill={badgeRole.highVisibiltyText ? badgeRole.textColor : undefined}
              fontFamily="'Avenir Bold', sans-serif"
              x={330}
              y={380}
              align="left"
              width={300}
              placeholder="CLAYTON, IN"
            />
          </Layer>
        </Stage>
        <BadgeControls canvasRef={frontStageRef} />
      </BadgeFaceContainer>

      <BadgeFaceContainer>
        <Stage width={width} height={height} ref={backStageRef}>
          <Layer>
            <Rect width={width} height={height} fill="white" />
            <ImageURL x={0} y={0} src={templateBack} width={width} />
            {!!approvedMetalException && (
              <ImageURL x={width - 140} y={85} src={'/images/badges/approved_metal_exception.png'} />
            )}
            {localBadgeState?.barcodeString ? (
              <Barcode
                code={localBadgeState.barcodeString}
                useShortBarcode={manassasBadge}
                ref={barcodeRef}
                width={manassasBadge ? barcodeDimensions.width * 1.3 : barcodeDimensions.width}
                onLoaded={onBarcodeLoaded}
                x={barcodePosition.x}
                y={barcodePosition.y}
              />
            ) : (
              <></>
            )}
          </Layer>
        </Stage>
        <BadgeControls canvasRef={backStageRef} />
      </BadgeFaceContainer>
      <Button onClick={onPrint} marginRight={2}>
        {t.print}
      </Button>
      {enableSaveButton && (
        <Button isLoading={isLoading} onClick={() => onUpdate?.(localBadgeState)}>
          {t.save}
        </Button>
      )}
    </Container>
  )
}
