import { Box, Divider, Flex, ViewMode, ViewModes } from 'components'
import React, { ReactNode } from 'react'
import { ItemCount, ItemCountProps } from './ItemCount'
import { useMobileDetect } from 'hooks'

type Props = ItemCountProps & {
  modes?: ViewMode[]
  children?: ReactNode
}

export const ActionBar = ({
  modes = [ViewMode.LIST, ViewMode.GRID],
  // modes = [ViewMode.LIST, ViewMode.GRID, ViewMode.MAP],
  children,
  ...itemCountProps
}: Props) => {
  const { isMobile } = useMobileDetect()
  return (
    <Flex direction={['column', 'row']} justify="flex-end" my={4} maxW={'100%'}>
      <Box flex={[null, 1]} mb={[4, 0]} mr={[0, 4]}>
        <ItemCount {...itemCountProps} />
      </Box>
      {!isMobile() && (
        <>
          <ViewModes modes={modes} justifyContent={['flex-start', 'flex-end']} mb={[(modes.length > 0 && 4) || 0, 0]} />
          {modes.length > 0 && <Divider orientation="vertical" h="24px" borderColor="dark.4" mx={4} />}
        </>
      )}
      <Flex flex={1} justifyContent={['flex-start', 'flex-end']} ml={[0, 4]} flexDirection={['column', 'row']}>
        {React.Children.map(children, (child, index) => {
          if (!child) return <></>

          return (
            <Box key={index} my={[2, 0]} mr={[0, 4]} float={['left', 'none']}>
              {child}
            </Box>
          )
        })}
      </Flex>
    </Flex>
  )
}
