import { AccessGroup, Zone } from 'libs/api'
import { FlatfileSchema } from '../index'

export type PeopleSchemaKey =
  | 'first_name'
  | 'last_name'
  | 'facial_rec_enabled'
  | 'employee_string'
  | 'badge_string'
  | 'credential'
  | 'barcode_string'
  | 'facility_string'
  | 'zone_ids'
  | 'access_group_ids'

export function getPeopleSchema(locations: Zone[], accessGroups: AccessGroup[]): FlatfileSchema<PeopleSchemaKey> {
  return [
    {
      key: 'first_name',
      label: 'First Name',
      isRequired: true,
    },
    {
      key: 'last_name',
      label: 'Last Name',
      isRequired: true,
    },
    {
      key: 'facial_rec_enabled',
      label: 'Facial Rec Enabled (true/false)',
      type: 'checkbox',
    },
    {
      key: 'employee_string',
      label: 'Company ID',
    },
    {
      key: 'barcode_string',
      label: 'Badge ID (Barcode)',
    },
    {
      key: 'credential',
      label: 'Credential (Facility Code & Prox Card)',
      description: "Only use this if the file doesn't have facility and prox card separated",
    },
    {
      key: 'facility_string',
      label: 'Facility ID',
    },
    {
      key: 'badge_string',
      label: 'Prox Card ID',
    },
    {
      key: 'zone_ids',
      label: 'Location',
      type: 'select',
      isRequired: true,
      options: locations.map((item) => ({ value: item.zone_id, label: item.name })),
    },
    {
      key: 'access_group_ids',
      label: 'Access Group',
      type: 'select',
      options: accessGroups.map((item) => ({ value: item.access_group_id, label: item.name })),
    },
  ]
}
