import { WsAuthorizationEvent } from 'components/AuthorizationEventsProvider'
import { AuthorizationEvent } from 'libs/api'

// transform events from REST API to match websocket format
export function transformHistoricEvent(event: AuthorizationEvent & { transit_event?: any }): WsAuthorizationEvent {
  return {
    ...event,
    id: event.authorization_event_id,
    device_group_id: event.device_group?.device_group_id || '',
    transit_event: event.transit_event,
    metadata: event.image
      ? {
          image_url: event.image.image_url,
          bounding_box: {
            x: event.image.bounding_box_origin_x,
            y: event.image.bounding_box_origin_y,
            width: event.image.bounding_box_width,
            height: event.image.bounding_box_height,
          },
        }
      : {},
    // zone_id: event.zone?.zone_id || '',
  }
}
