import React from 'react'
import { ParsedColumn, ParsedColumns } from '../helpers/parseColumns'
import { TableHeadCell, TableHeadRow } from '../../../../components/core/Table'
import { Box, Icon } from '@chakra-ui/react'
import { OrderDirection } from 'libs/api'
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { Flex } from 'components'

export type OrderProps = {
  orderBy?: string
  orderDirection: OrderDirection
  setOrderBy: (orderBy: string) => void
  setOrderDirection: (orderDirection: OrderDirection) => void
}

interface Props<T> {
  columns: ParsedColumns<T>
  orderProps?: OrderProps
}

const renderSortIcon = (column: ParsedColumn<any>, orderProps?: OrderProps) => {
  if (!column.sortKey) return null

  if (orderProps?.orderBy === column.sortKey) {
    return (
      <div>
        <Icon as={orderProps.orderDirection === 'ASC' ? ChevronUpIcon : ChevronDownIcon} boxSize="14px" ml={1} />
      </div>
    )
  }

  return (
    <Box color="gray.500" ml={1}>
      <ChevronUpIcon boxSize="14px" />
      <ChevronDownIcon boxSize="14px" />
    </Box>
  )
}

export function ListPageHeader<T>({ columns, orderProps }: Props<T>) {
  function handleClick(column: ParsedColumn<T>) {
    if (orderProps && column.sortKey) {
      orderProps.setOrderBy(column.sortKey)
      orderProps.setOrderDirection(
        orderProps.orderBy !== column.sortKey
          ? orderProps.orderDirection
          : orderProps.orderDirection === 'ASC'
          ? 'DESC'
          : 'ASC'
      )
    }
  }
  return (
    <TableHeadRow>
      {columns.map((column) => (
        <TableHeadCell
          key={column.key}
          width={column.width}
          onClick={column.sortKey ? () => handleClick(column) : undefined}
          textAlign={column.textAlign}
        >
          <Flex>
            {column.title} {renderSortIcon(column, orderProps)}
          </Flex>
        </TableHeadCell>
      ))}
    </TableHeadRow>
  )
}
