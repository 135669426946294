import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const DocumentIcon = createIcon({
  displayName: 'DocumentIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C5.44772 2 5 2.44772 5 3V21C5 21.5523 5.44772 22 6 22H18C18.5523 22 19 21.5523 19 21V8.41421C19 8.01639 18.842 7.63486 18.5607 7.35355L13.6464 2.43934C13.3651 2.15804 12.9836 2 12.5858 2H6ZM7 4H12V8C12 8.55228 12.4477 9 13 9H17V20H7V4ZM14 3.91421L16.0858 6H14V3.91421Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
