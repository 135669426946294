import React, { ComponentProps, useEffect, useMemo, useState } from 'react'
import { useField } from 'formik'
import { FormControl, FormControlProps, FormErrorMessage, InputGroup } from '@chakra-ui/react'
import Select from 'react-select'
import { FormLabel } from './FormLabel'
import { useSelectStyles } from './hooks/useSelectStyles'

export interface OptionType {
  value: any
  label: string
  isFixed?: boolean
}

export type SelectFieldProps = {
  label?: string
  options: OptionType[]
  name: string
  type?: string
  placeholder?: string
  isMulti?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  width?: number
  SelectProps?: ComponentProps<typeof Select>
  onValue?: (value: string | string[]) => void
  afterChange?: (value: string | string[]) => void
} & FormControlProps

export const SelectField = ({
  label,
  options,
  name,
  id,
  type,
  placeholder,
  isMulti,
  isLoading,
  isDisabled,
  afterChange,
  onValue,
  SelectProps,
  width,
  ...props
}: SelectFieldProps) => {
  const [field, meta, helper] = useField(name)
  const [touched, setTouched] = useState(false)
  const styles = useSelectStyles()

  const selectValue = useMemo(
    () =>
      isMulti
        ? (field.value || []).map((value: any) => options.find((option) => option.value === value))
        : options.find((option) => option.value === field.value) ?? '',
    [isMulti, options, field]
  )

  useEffect(() => {
    if (afterChange) {
      afterChange(meta.value)
    }
  }, [meta.value, afterChange])

  // Fix not touched when value is removed and isMulti
  useEffect(() => {
    if (!touched && meta.touched) setTouched(true)
    if (touched && !meta.touched) helper.setTouched(true)
  }, [meta.touched, touched, helper])

  return (
    <FormControl isInvalid={!!meta.error && meta.touched} isDisabled={isDisabled} {...props}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <InputGroup width={width}>
        <Select
          fontSize="sm"
          borderRadius={0}
          placeholder={placeholder}
          value={selectValue}
          onChange={(values, { action, removedValue }) => {
            if (!meta.touched) helper.setTouched(true)
            switch (action) {
              case 'remove-value':
              case 'pop-value':
                if (removedValue?.isFixed) {
                  return
                }
                break
              case 'clear':
                values = options.filter((value) => value.isFixed)
                break
            }
            let _values = null
            if (isMulti) {
              _values = (values ?? []).map((value: OptionType) => value.value)
            } else {
              _values = (values as OptionType)?.value
            }
            helper.setValue(_values)

            onValue && onValue(_values)
          }}
          onBlur={field.onBlur}
          options={options}
          styles={styles}
          isMulti={isMulti}
          isLoading={isLoading}
          isDisabled={isDisabled}
          {...SelectProps}
        />
      </InputGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
