import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react'
import { Box } from 'components'
import { BoxProps, CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import translations from './PhotoUpload.i18n.json'
import { useTranslations, useNotify, useAuthCredentials } from 'hooks'
import { Label } from 'components/core/Label'
import { fileToAttachment } from 'utils/form'
import { signUrl } from 'utils/aws/util'
import { Button } from 'components'
import { UploadArea } from 'components/UploadArea'
import { DropzoneOptions } from 'react-dropzone'
import Cropper from 'react-cropper'
import { CheckIcon, CloseIcon } from 'components/icons'
import { IconButton } from 'components/core'

const overlayStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

enum UIStates {
  IDLE,
  UPLOADING,
  CROPPING_IMAGE,
}

type Props = BoxProps & {
  label?: string
  placeholder?: string
  value?: string
  options?: DropzoneOptions
  onURL?: (url: string, setSelectedImageURL: Dispatch<SetStateAction<string | undefined>>) => void
  dontUpload?: boolean
}

export function PhotoUpload({ label, placeholder, onURL, value, options, dontUpload, ...boxProps }: Props) {
  const t = useTranslations(translations)
  const credentials = useAuthCredentials()
  const notify = useNotify()
  const [UIState, setUIState] = useState(UIStates.IDLE)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [selectedImageURL, setSelectedImageURL] = useState(value ? signUrl(value, credentials) : undefined)
  const cropperRef = useRef<Cropper | null>(null)

  const handleDropRejected = useCallback(([file]) => {
    const messages = file.errors.map((error: any) => error.message).join()
    notify.error(messages || t.invalidFile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDropAccepted = useCallback(
    async ([file]) => {
      setSelectedImageURL(URL.createObjectURL(file))
      setUIState(UIStates.CROPPING_IMAGE)
    },
    [setSelectedImageURL]
  )

  const handleFinishCropping = useCallback(async () => {
    const croppedFile = await new Promise<File>((resolve, reject) =>
      cropperRef
        .current!.getCroppedCanvas()
        .toBlob((blob) => (blob ? resolve(new File([blob], `file-${new Date().getTime()}`)) : reject()), 'image/jpeg')
    )

    setSelectedImageURL(URL.createObjectURL(croppedFile))
    setUIState(UIStates.UPLOADING)

    if (!dontUpload) {
      const attachment = await fileToAttachment(croppedFile, (progressEvent) => {
        setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
      })
      if (attachment) {
        onURL?.(attachment.url, setSelectedImageURL)
      }
    } else {
      setUploadProgress(100)
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        onURL?.(base64String, setSelectedImageURL)
      }
      reader.readAsDataURL(croppedFile)
    }
  }, [onURL, dontUpload])

  const handleCancelCropping = useCallback(() => {
    setSelectedImageURL(undefined)
    setUIState(UIStates.IDLE)
  }, [])

  const handleChangeClick = useCallback(() => {
    setSelectedImageURL(undefined)
  }, [])

  return (
    <Box {...boxProps}>
      {label && (
        <Label fontSize="xs" shade={1} mb={1}>
          {label}
        </Label>
      )}
      <Box>
        {selectedImageURL ? (
          <>
            {UIState === UIStates.UPLOADING && (
              <Box width="118px" height="118px">
                <Box {...overlayStyles} style={{ backdropFilter: `grayscale(100%) brightness(0.2)` }}>
                  <CircularProgress value={uploadProgress} color="primary">
                    <CircularProgressLabel>{uploadProgress}%</CircularProgressLabel>
                  </CircularProgress>
                </Box>
              </Box>
            )}

            {UIState === UIStates.CROPPING_IMAGE && (
              <Box>
                <Cropper
                  style={{ width: `${boxProps.width}px` }}
                  initialAspectRatio={2 / 3}
                  aspectRatio={2 / 3}
                  preview=".img-preview"
                  src={selectedImageURL!}
                  viewMode={1}
                  guides={true}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    cropperRef.current = instance
                  }}
                />
                <IconButton aria-label="Finish Cropping" onClick={handleFinishCropping}>
                  <CheckIcon />
                </IconButton>
                <IconButton aria-label="Cancel Cropping" onClick={handleCancelCropping}>
                  <CloseIcon />
                </IconButton>
              </Box>
            )}

            {UIState === UIStates.IDLE && (
              <Box
                width="118px"
                height="118px"
                bgShade={1}
                backgroundImage={`url("${selectedImageURL}")`}
                backgroundSize="cover"
                backgroundPosition="center"
              >
                <Box {...overlayStyles} opacity={0} _hover={{ opacity: 1 }} bg="rgba(0,0,0,0.8)">
                  <Button onClick={handleChangeClick} variant="outline" fontSize="xs" px={4}>
                    {t.change}
                  </Button>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <UploadArea
            accept="image/*"
            multiple={false}
            onDropAccepted={handleDropAccepted}
            onDropRejected={handleDropRejected}
            placeholder={placeholder}
            {...options}
          />
        )}
      </Box>
    </Box>
  )
}
