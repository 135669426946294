import React from 'react'
import { Text } from 'react-konva'
import Konva from 'konva'

type Props = {
  value: string
  placeholder?: string
  onChange?: (newValue: string) => void
} & Omit<Konva.TextConfig, 'text'>

export const EditableText: React.FC<Props> = ({ value, placeholder, onChange, ...textProps }) => {
  const displayPlaceholder = !value

  function onDoubleClick() {
    const newText = window.prompt('Edit Text', value)
    if (newText) {
      onChange?.(newText)
    }
  }

  function handleMouseOver(e: any) {
    const stage = e.target.getStage()
    stage.container().style.cursor = 'pointer'
  }

  function handleMouseOut(e: any) {
    const stage = e.target.getStage()
    stage.container().style.cursor = 'default'
  }

  return (
    <Text
      fill={displayPlaceholder ? '#e0e0e0' : 'black'}
      {...textProps}
      onDblClick={onDoubleClick}
      text={value || placeholder || ''}
      // Add event handlers for cursor change
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    />
  )
}
