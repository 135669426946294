import { Divider, Flex, H6, Box, Text } from 'components'
import React from 'react'
import { SystemProps, SystemStyleObject } from '@chakra-ui/react'

export type SectionPaneProps = SystemProps & {
  title: string
  subtitle?: string
  icon?: React.ReactNode
  children: React.ReactNode
  actions?: React.ReactNode
  styles?: Partial<Record<'title' | 'divider' | 'header' | 'root', SystemStyleObject>>
}

export const SectionPane = ({
  icon,
  title,
  subtitle,
  children,
  actions,
  styles = {},
  ...systemProps
}: SectionPaneProps) => (
  <Box {...systemProps} sx={styles.root}>
    <Flex align="center" justify="space-between" mb={3} sx={styles.header}>
      {icon}
      <H6 shade={1} flexGrow={1} sx={styles.title}>
        {title}
      </H6>
      {actions}
    </Flex>
    {subtitle && (
      <Text my={3} shade={1}>
        {subtitle}
      </Text>
    )}
    <Divider sx={styles.divider} />
    {children}
  </Box>
)
