import React, { useState } from 'react'
import { Flex } from 'components'
import { BoxProps, useColorMode } from '@chakra-ui/react'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Chip } from 'components/Chip'

type Props = {
  maxVisible?: number
  multiLine?: boolean
  display?: string | string[]
  spacing?: number
  items: Array<{
    color: string
    name: string
  }>
} & BoxProps

export function ChipList({ display, maxVisible = 1, items, multiLine = false, spacing = 1, ...boxProps }: Props) {
  const { colorMode } = useColorMode()
  const [isExpanded, setIsExpanded] = useState(maxVisible > items.length)

  function handleExpandClick(event: React.SyntheticEvent) {
    event.stopPropagation()
    setIsExpanded(true)
  }

  function handleCollapseClick(event: React.SyntheticEvent) {
    event.stopPropagation()
    setIsExpanded(false)
  }

  const visibleItems = isExpanded ? items : items.slice(0, maxVisible)

  const buttonColor = colorMode === 'light' ? 'light.4' : 'dark.0'

  return (
    <Flex fontFamily="label" fontWeight="bold" fontSize="xs" flexWrap={multiLine ? 'wrap' : 'nowrap'} {...boxProps} display={display}>
      {visibleItems.map((item) => (
        <Chip key={item.name} color={item.color} mr={spacing} mb={multiLine && isExpanded ? spacing : 0}>
          {item.name}
        </Chip>
      ))}
      {items.length > maxVisible &&
        (!isExpanded ? (
          <Flex
            onClick={handleExpandClick}
            align="center"
            justify="center"
            bg={items[0].color}
            color={buttonColor}
            borderRadius={2}
            w="2.43rem"
            cursor="pointer"
          >
            +{items.length - maxVisible}
          </Flex>
        ) : (
          <Flex
            align="center"
            justify="center"
            p={2}
            borderRadius={2}
            onClick={handleCollapseClick}
            color={buttonColor}
            bg={items[items.length - 1].color}
            cursor="pointer"
            mb={multiLine && isExpanded ? 1 : 0}
          >
            <ChevronLeftIcon boxSize="18px" />
          </Flex>
        ))}
    </Flex>
  )
}
