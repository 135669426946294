import { Auth } from 'aws-amplify'

export const useLogout = () => async () => {
  try {
    await Auth.signOut()
    localStorage.setItem('currentRoleId', '')
    localStorage.setItem('fetchCurrentUserRoles', '[]')

    window.location.reload()
  } catch (e) {
    alert(e)
  }
}
