import Amplify from 'aws-amplify'
import axios from 'axios'
import { getCurrentRoleHeader } from 'components/AuthContextProvider'
import PubSub from 'pubsub-js'
import Analytics from '@aws-amplify/analytics'

const defaultEndpoint = {
  region: process.env.REACT_APP_API_GATEWAY_REGION,
  endpoint: process.env.REACT_APP_API_GATEWAY_URL,
  custom_header: async () => ({
    'X-Keee-Current-Role': await getCurrentRoleHeader(),
  }),
}

const endpoints = [
  { name: 'categories', ...defaultEndpoint },
  { name: 'config', ...defaultEndpoint },
  { name: 'events', ...defaultEndpoint },
  { name: 'lists', ...defaultEndpoint },
  { name: 'people', ...defaultEndpoint },
  { name: 'things', ...defaultEndpoint },
  { name: 'users', ...defaultEndpoint },
  { name: 'tenant-locations', ...defaultEndpoint },
  { name: 'notifications', ...defaultEndpoint },
  { name: 'billing', ...defaultEndpoint },
  { name: 'zones', ...defaultEndpoint },
  { name: 'device-groups', ...defaultEndpoint },
  { name: 'authorization-events', ...defaultEndpoint },
  { name: 'access-groups', ...defaultEndpoint },
]

axios.interceptors.response.use(
  (response) => response,
  function (error) {
    if (error.response?.status === 403 && error.response.data?.code?.includes('Current role must be')) {
      PubSub.publish('currentRoleError', error)
    }
    return Promise.reject(error)
  }
)

Amplify.configure({
  API: {
    endpoints,
  },
})

export function configureAmplifyAuth(cognitoConfig: Record<string, string>) {
  const baseAwsConfig = {
    region: cognitoConfig['region'],
    userPoolId: cognitoConfig['user-pool-id'],
    identityPoolId: cognitoConfig['identity-pool-id'],
    userPoolWebClientId: cognitoConfig['app-client-id'],
  }
  Analytics.configure({
    AWSPinpoint: {
      appId: 'ca21c91054064c5b9953479d60f2e304',
      region: cognitoConfig['region'],
    },
  })
  Analytics.autoTrack('session', {
    enable: false,
  })
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      ...baseAwsConfig,
    },
  })
  /*
  Amplify.configure({
    oauth: {
      domain: 'keee.auth.us-east-2.amazoncognito.com', // TODO test other user pools
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'], // is 'user.admin' needed?
      redirectSignIn: 'http://localhost:3000',
      redirectSignOut: 'http://localhost:3000',
      responseType: 'code',
    },
  })
  */
}
