import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useMobileDetect } from 'hooks/useMobileDetect'

const NAV_COLLAPSED_KEY = 'navigationCollapsed'

export const useNavigationCollapse = () => {
  const { isMobile } = useMobileDetect()

  const [isNavigationCollapsed, setIsNavigationCollapsed] = useState(
    localStorage.getItem(NAV_COLLAPSED_KEY) === 'true' || isMobile()
  )

  useEffect(() => {
    localStorage.setItem(NAV_COLLAPSED_KEY, isNavigationCollapsed.toString())
  }, [isNavigationCollapsed])

  return [isNavigationCollapsed, setIsNavigationCollapsed] as [boolean, Dispatch<SetStateAction<boolean>>]
}
