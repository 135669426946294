import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import { TimePicker } from './TimePicker'
import { DateRange as ReactDateRange } from 'react-date-range'
import { endOfDay, format, startOfDay } from 'date-fns'
import { Input, useColorMode } from '@chakra-ui/react'
import { useBgColorFromShade } from '../core/utils'
import { Box } from '../core'

const Wrapper = styled(Box)`
  position: relative;
  display: inline-block;
`

const CalendarWrapper = styled.div`
  background: white;
  position: absolute;
  z-index: 500;
  top: 100%;
  left: 0;
  box-shadow: 0 0.6px 1.2px rgba(0, 0, 0, 0.035), 0 1.8px 3.3px rgba(0, 0, 0, 0.05), 0 4.2px 7.8px rgba(0, 0, 0, 0.065),
    0 14px 26px rgba(0, 0, 0, 0.1);
`

const TimePickerWrapper = styled.div`
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${TimePicker.toString()} {
    flex-basis: 50%;
    width: 50%;
  }
`

const CustomInput = styled(Input)`
  border-radius: 0;
  width: 350px;
  font-size: 14px;
`

export type Range = {
  startDate: Date | null
  endDate: Date | null
}

export type DateRangeProps = {
  value: Range
  withTime?: boolean
  minDate?: Date
  maxDate?: Date
  onChange?: (change: Range) => void
}

function parseDaytime(time: string) {
  let [hours, minutes] = time.split(':').map(Number)
  if (time.toUpperCase().includes('PM') && hours !== 12) hours += 12
  return 1000 /*ms*/ * 60 /*s*/ * (hours * 60 + minutes)
}

function setTimeToDate(date: Date, time: string) {
  return new Date(startOfDay(date).getTime() + parseDaytime(time))
}

function formatDate(date: Date, withTime: boolean) {
  return withTime ? format(date, 'd LLL Y, p') : format(date, 'd LLL Y')
}

export function DateRange({
  value,
  withTime = true,
  minDate,
  maxDate = new Date(),
  onChange = (range) => {},
}: DateRangeProps) {
  const [state, setState] = useState<Range>({
    startDate: new Date(),
    endDate: null,
  })

  const [active, setActive] = useState(false)
  const inputContainerRef = useRef<HTMLDivElement | null>(null)
  const onClickOutside = useCallback((e) => {
    if (inputContainerRef.current?.contains(e.target) === false) {
      setActive(false)
    }
  }, [])
  const { colorMode } = useColorMode()
  const backgroundColor = useBgColorFromShade(4)
  const borderColor = colorMode === 'dark' ? 'dark.1' : 'gray.200'

  useEffect(() => {
    if (!active) {
      document.removeEventListener('click', onClickOutside)
      return
    }

    document.addEventListener('click', onClickOutside)
    return () => {
      document.removeEventListener('click', onClickOutside)
    }
  }, [active, onClickOutside])

  useEffect(() => {
    setState(value)
  }, [value])

  const inputValue = useMemo(() => {
    if (state.startDate && state.endDate) {
      return `${formatDate(state.startDate, withTime)} — ${formatDate(state.endDate, withTime)}`
    }

    if (state.startDate) {
      return `${formatDate(state.startDate, withTime)}`
    }

    return ''
  }, [state, withTime])

  function onDateChange(range: Range) {
    const nextState = {
      startDate: range.startDate ? startOfDay(range.startDate) : range.startDate,
      endDate: range.endDate ? endOfDay(range.endDate) : range.endDate,
    }
    setState(nextState)
    onChange(nextState)
  }

  function onStartDateTimeChange(change: string) {
    if (state.startDate) {
      const nextState: Range = { ...state, startDate: setTimeToDate(state.startDate, change) }
      setState(nextState)
      onChange(nextState)
    }
  }

  function onEndDateTimeChange(change: string) {
    if (state.endDate) {
      const nextState: Range = { ...state, endDate: setTimeToDate(state.endDate, change) }
      setState(nextState)
      onChange(nextState)
    }
  }

  return (
    <Wrapper ref={inputContainerRef} backgroundColor={backgroundColor} borderColor={borderColor}>
      <CustomInput
        onClick={() => {
          setActive(true)
        }}
        readOnly
        value={inputValue}
      />
      <CalendarWrapper>
        {active && (
          <>
            <ReactDateRange
              onChange={(item: any) => {
                onDateChange(item.range1 as Range)
              }}
              moveRangeOnFirstSelection={false}
              ranges={[state] as any}
              direction="horizontal"
              months={2}
              minDate={minDate}
              maxDate={maxDate}
              showDateDisplay={false}
            />
            {withTime && (
              <TimePickerWrapper>
                <TimePicker onChange={onStartDateTimeChange} value={state.startDate?.toLocaleTimeString() || null} />
                <TimePicker onChange={onEndDateTimeChange} value={state.endDate?.toLocaleTimeString() || null} />
              </TimePickerWrapper>
            )}
          </>
        )}
      </CalendarWrapper>
    </Wrapper>
  )
}
