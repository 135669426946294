import { Button } from '@chakra-ui/react'
import Konva from 'konva'
import { MutableRefObject } from 'react'
import { FaPrint } from 'react-icons/fa'
import { BadgeFaceControls } from './Badge.styed'
import printJs from 'print-js'

export const BadgeControls = (props: { canvasRef: MutableRefObject<Konva.Stage | null> }) => {
  function onPrint() {
    if (props.canvasRef.current) {
      const canvas = props.canvasRef.current?.toCanvas({})

      canvas?.toBlob(
        (blob) => {
          const url = blob ? URL.createObjectURL(blob) : ''
          printJs(url, 'image')
        },
        'image/jpeg',
        0.9
      )
    }
  }

  return (
    <BadgeFaceControls>
      <Button color="blue" onClick={onPrint}>
        <FaPrint />
      </Button>
    </BadgeFaceControls>
  )
}
