import React, { ReactNode } from 'react'
import {
  Modal as ChakraModal,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  ModalBody,
  ModalProps,
  Button,
} from '@chakra-ui/react'
import { Box } from './Box'
import { useTextColorFromShade } from './utils'
import { useMobileDetect } from 'hooks'
import { ArrowBackIcon } from '@chakra-ui/icons'

type Props = {
  children: ReactNode
  footer?: ReactNode
  visible?: boolean
  isVisible?: boolean
  onClose?: () => void
  title?: string
} & Omit<ModalProps, 'isOpen'>

export const MODAL_CLOSE_DURATION = 150

export const Modal: React.FC<Props> = ({
  children,
  footer,
  visible,
  onClose,
  title,
  isVisible,
  size,
  ...modalProps
}) => {
  const contentColor = useTextColorFromShade(2)
  const { isMobile } = useMobileDetect()

  if (typeof visible !== 'undefined') {
    console.warn('Modal `visible` prop will be deprecated. Use `isVisible` instead')
  }

  const isModalVisible = typeof isVisible !== 'undefined' ? isVisible : (visible as boolean)

  if (isMobile()) {
    if (!isModalVisible) {
      return <></>
    }

    return (
      <Box
        p={4}
        position={'absolute'}
        zIndex={10000}
        left={0}
        top={0}
        bg="white"
        overflowX={'hidden'}
        overflowY={'scroll'}
        minWidth="100%"
        maxWidth="100%"
        w="100%"
      >
        <Button leftIcon={<ArrowBackIcon />} colorScheme="blue" onClick={onClose} mb={4}>
          Back
        </Button>
        <Box fontWeight="bold" fontFamily="label" mb={2} shade={0}>
          {title}
        </Box>
        <Box overflowX={'hidden'} maxWidth="100%">
          {children}
        </Box>
        {footer && <Box p={8}>{footer}</Box>}
      </Box>
    )
  }

  // Render as a modal
  return (
    <ChakraModal
      isOpen={isModalVisible}
      onClose={onClose!}
      isCentered={false}
      autoFocus={false}
      motionPreset="slideInBottom"
      size={size}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent color={contentColor} fontSize="sm" borderRadius={0}>
        <ModalBody p={8}>
          {title && (
            <Box fontWeight="bold" fontFamily="label" mb={2} shade={0}>
              {title}
            </Box>
          )}
          {children}
        </ModalBody>
        {footer && (
          <ModalFooter p={8} pt={2}>
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  )
}
